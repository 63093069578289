import { mapActions, mapGetters } from 'vuex'

const app = {
  data () {
    return {
      imageBase: process.env.VUE_APP_IMAGE_ROOT,
    }
  },
  computed: {
    ...mapGetters({
      appId: 'appId'
    })
  },
  methods: {
    ...mapActions({
      setAppId: 'setAppId'
    })
  }
}

export default app