<template>
  <div class="main">
    <header-item :context="header"/>
    <div class="main-menu">
      <menu-item v-for="(item, id) in items"
        :key="id"
        :context="item"/>
    </div>
  </div>
</template>

<script>
import HeaderItem from '@/components/HeaderItem.vue'
import MenuItem from '@/components/MenuItem.vue'

export default {
  name: 'MainView',
  components: { HeaderItem, MenuItem },
  data () {
    return {
      header: {
        image: 'lander_popv1.png',
        alt: 'image description'
      },
      items: [
        {
          data: 'domething',
          image: '750x640.png',
          style: 'card',
          text: 'I am the copy that a card uses',
          button: 'Click Now'
        },
        {
          data: 'domething',
          image: '750x480.png',
          style: 'card',
          text: 'I am the copy that a card uses',
          button: 'Click Now'
        },
        {
          data: 'domething',
          image: '750x320.png',
          style: 'card',
          text: 'I am the copy that a card uses',
          button: 'Click Now'
        },
        {
          data: 'domething',
          image: '750x640.png',
          style: 'banner'
        },
        {
          data: 'domething',
          image: '750x480.png',
          style: 'banner'
        },
        {
          data: 'domething',
          image: '750x320.png',
          style: 'banner'
        },
        {
          image: '',
          style: 'bar'
        },
        {
          image: '',
          style: 'bar'
        },
        {
          image: '',
          style: 'bar'
        },
        {
          image: '',
          style: 'bar'
        }
      ]
    }
  },
  async mounted () {
  }
}
</script>
