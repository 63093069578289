<template>
  <header>
    <div class="header-left">a</div>
    <div class="header-center">
      <image-asset
        :context="context"
        class="logo"
        />
    </div>
    <div class="header-right">b</div>
  </header>
</template>

<script>
import ImageAsset from './elements/ImageAsset.vue'
export default {
  name: 'HeaderItem',
  components: {
    ImageAsset
  },
  props: {
    context: {
      type: Object,
      required: true
    }
  }
}
</script>

<style>

</style>