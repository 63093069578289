import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import core from '@/libs/mixins/core.mixins'


createApp(App)
.mixin({mixins: [core] })
.use(store)
.use(router)
.mount('#app')
