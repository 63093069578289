const MUTATE_APP_ID = 'mutateAppId'

const state = {
  appId: null
}

const getters = {
  appId: state => state.appId
}

const mutations = {
  mutateAppId (state, id) {
    state.appId = id
  }
}

const actions = {
  setAppId ({ commit }, id) {
    commit(MUTATE_APP_ID, id)
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}