<template>
  <img :src="`${imageBase}/${context.image}`"
    :alt="context.alt" />
</template>

<script>
export default {
  name: 'ImageAsset',
  props: {
    context: {
      type: Object,
      required: true
    }
  }
}
</script>