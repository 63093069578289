<template>
  <div class="menu-item">
    
    <span v-if="context.style === 'card'">
      <div class="menu-card">
        <div class="menu-card-image">
          <image-asset 
            :context="context"/>
        </div>
        <div class="menu-card-content">
          {{ context.text }}<br/>
          {{ context.button }}
        </div>
      </div>
    </span>

    <span v-if="context.style === 'banner'">
      <image-asset 
        :context="context"/>
    </span>

    <span v-if="context.style === 'bar'">
      <div class="menu-bar">
        <div class="menu-bar-label">
          label
        </div>
        <div class="menu-bar-icon">
          icon
        </div>
      </div>
    </span>

  </div>
</template>

<script>
import ImageAsset from './elements/ImageAsset.vue'
export default {
  name: 'MenuItem',
  components: { ImageAsset },
  props: {
    context: {
      type: Object,
      requried: true
    }
  }
}
</script>